@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.masonry {
    --gap: 1em;
    --columns: 4;
    max-width: 60rem;
    margin: 0 auto;
    display: column;
    columns: var(--columns);
    gap: var(--gap);
}

.masonry > * {
    break-inside: avoid;
    margin-bottom: var(--gap);
}
